// import { Navigation } from './components/Navigation';
// import { Footer } from './components/Footer';

/* const navigation = [
  {
    name: 'Home',
    href: '#',
    current: true,
  },
  {
    name: 'Blog',
    href: '#',
    current: false,
  },
  {
    name: 'Projects',
    href: '#',
    current: false,
  },
]; */

function App() {
  return (
    <div className="container mx-auto py-2">
      {/* Header */}
      {/* <header className="hidden md:block border-2 text-center">
        My Header
      </header> */}
      {/* Navigation */}
      {/* <nav class="container flex justify-between px-4 py-8 mx-auto bg-white">
        <div>
          <h3 class="text-2xl font-medium text-blue-500">LOGO</h3>
        </div>
        <div class="hidden space-x-8 lg:flex">
          <a href="">Home</a>
          <a href="">About Us</a>
          <a href="">Blogs</a>
          <a href="">Our Team</a>
          <a href="">Contact Us</a>
        </div>
        <div class="flex lg:hidden">
          <svg
            id="mobile-nav-open"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 10h16M4 14h16M4 18h16"
            />
          </svg>
          <svg
            id="mobile-nav-close"
            xmlns="http://www.w3.org/2000/svg"
            className="lg:hidden h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </nav> */}
      {/* <nav className="flex border-2">
        <div className="">
          <img className="" src="http://via.placeholder.com/150x45/" />
        </div>
        <div className="hidden md:block flex justify-end">
          <ul className="flex items-center">
            {navigation.map((item) => (
              <li className="flex-auto border-4">
                <a className="" key={item.name} href={item.href}>
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="items-end">foo</div>
      </nav> */}
      <main>
        <div className="flex flex-col justify-center items-center h-[75vh]">
          <h1 className="text-4xl md:text-3xl font-bold underline text-center">
            <a href="https://www.linkedin.com/in/meng-lim/">Meng Lim</a>
          </h1>
          {/* <div className="text-center"> */}
          <ul className="py-1.5 md:flex text-center">
            <li>
              Marketing Technologist
              <span className="hidden md:inline-block">&nbsp;|&nbsp;</span>
            </li>
            <li>
              Solutions Consultant
              <span className="hidden md:inline-block">&nbsp;|&nbsp;</span>
            </li>
            <li className="text-sky-900 italic">Software Engineer</li>
          </ul>
          {/* </div> */}
          {/* <p>Hello world!</p>
          <a
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a> */}
        </div>
      </main>
      <footer></footer>
    </div>
  );
}

export default App;
